import {
  Banner,
  Button,
  FormLayout,
  LegacyCard,
  LegacyStack,
  Tabs,
  Select,
  Text,
  TextField,
  TabProps,
  Checkbox,
} from "@shopify/polaris";
import {
  CalendarMajor,
  CapturePaymentMinor,
  ClipboardMinor,
  ClockMajor,
  DuplicateMinor,
} from "@shopify/polaris-icons";
import { Box } from "@smartrr/shared/components/primitives";
import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import { ISellingPlanFromZodWithId } from "@smartrr/shared/entities/SellingPlanGroup";
import {
  SellingPlanAnchorInput,
  SellingPlanInterval,
  SellingPlanRecurringDeliveryPolicyPreAnchorBehavior,
} from "@smartrr/shared/shopifyGraphQL/api";
import { copyToClipboard } from "@smartrr/shared/utils/copyToClipboard";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { SellingPlanGroupStoreAccess } from "@vendor-app/app/_state/zustand/SellingPlansStore";

import { AnchorDatesForm } from "./AnchorDatesForm";
import { PricingPolicies } from "./PricingPolicies";
import { useSmartrrVendorSelector } from "../../../_state/typedVendorReduxHooks";
import { isFeatureAvailable } from "../../components/authorization/featureAccess";
import { createSmartrrSellingPlanCopy } from "../utils";
import { useIsAddingNewPlanDisabled } from "./hooks/useIsAddingNewPlanDisabled";

interface Props {
  groupId?: string;
  index: number;
  sellingPlan: ISellingPlanFromZodWithId;

  onUpdateSellingPlan(index: number, properties: { [key: string]: any }): void;

  setSellingPlanInputs: React.Dispatch<React.SetStateAction<ISellingPlanFromZodWithId[]>>;
}

const intervalOptions = Object.values(SellingPlanInterval).map(interval => ({
  label: `${interval.charAt(0)}${interval.slice(1).toLowerCase()}s`,
  value: interval,
}));

const preAnchorBehaviorOptions = [
  { label: "ASAP", value: SellingPlanRecurringDeliveryPolicyPreAnchorBehavior.Asap },
  { label: "NEXT", value: SellingPlanRecurringDeliveryPolicyPreAnchorBehavior.Next },
];

enum SubscriptionProgramTabs {
  BASIC_DETAILS,
  PREPAID,
  MAXMIN,
  ANCHOR,
}

const EqualWidthItemsContainer = styled(Box)`
  margin-left: calc(var(--p-space-5) * -1);
  margin-top: calc(var(--p-space-4) * -1);

  & > div {
    flex: 1 1 13.75rem;
    flex-basis: 13.75rem;
    margin-left: var(--p-space-5);
    margin-top: var(--p-space-4);
    max-width: calc(100% - var(--p-space-5));
    min-width: 6.875rem;
  }
`;

const TabContainer = styled.div`
  a:hover,
  a:focus {
    text-decoration: none;
  }
`;

const BannerContainer = styled.div`
  .Polaris-Banner {
    border-radius: 0.5rem;
  }
`;

const SemiBoldText = styled.span`
  font-weight: 500;
`;

const MaxMinCardContainer = styled.div`
  .Polaris-LegacyCard__Section {
    display: flex;
    flex-direction: column;
    height: 28rem;
    justify-content: space-between;
  }
`;

const SellingPlanID = styled.div`
  border-bottom: var(--p-border-width-1) solid var(--p-color-border-subdued);
  margin-top: -10px;
  padding: 0px 20px 10px 20px;
`;

export const CopyClipboard = styled.div`
  position: relative;

  & .copy-btn {
    position: absolute;
    right: 0%;

    & .Polaris-Icon__Svg {
      fill: #5c5f62;
    }
  }
`;

export function SellingPlan({
  index,
  sellingPlan,
  onUpdateSellingPlan,
  setSellingPlanInputs,
}: Props): JSX.Element {
  useEffect(() => {
    const defaultInterval = sellingPlan.billingPolicy?.recurring?.interval || SellingPlanInterval.Week;
    onUpdateSellingPlan(index, {
      "billingPolicy.recurring.interval": defaultInterval,
      "deliveryPolicy.recurring.interval": defaultInterval,
    });
  }, []);

  const anchors = sellingPlan.billingPolicy?.recurring?.anchors ?? [];
  const billingIntervalCount = sellingPlan.billingPolicy?.recurring?.intervalCount;
  const deliveryIntervalCount = sellingPlan.deliveryPolicy?.recurring?.intervalCount;

  const minimumBillingCycles = sellingPlan.billingPolicy?.recurring?.minCycles;
  const maximumBillingCycles = sellingPlan.billingPolicy?.recurring?.maxCycles;

  const [selectedTab, setSelectedTab] = useState<SubscriptionProgramTabs>(SubscriptionProgramTabs.BASIC_DETAILS);

  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);
  const user = useSmartrrVendorSelector(state => state.auth.user);

  const isPrepaidAvailable = isFeatureAvailable(FeatureEnum.PREPAID_SELLING_PLAN, user, activePlan);
  const isMaxMinAvailable = isFeatureAvailable(FeatureEnum.SEQUENTIAL_SELLING_PLAN, user, activePlan);

  const validationErrors = SellingPlanGroupStoreAccess.useValidationErrors();
  const hideAddAnotherPlan = useIsAddingNewPlanDisabled();

  const { addToast } = useToast();

  const tabs: TabProps[] = [
    {
      id: `basic_details_${index + 1}`,
      content: "Basic details",
      accessibilityLabel: "basic_details",
      panelID: "basic_details-panel",
    },
    {
      id: `prepaid_${index + 1}`,
      content: "Prepaid",
      disabled: !isPrepaidAvailable,
      accessibilityLabel: "Prepaid",
      panelID: "prepaid-panel",
    },
    {
      id: `max-min_${index + 1}`,
      content: "Max/Min",
      disabled: !isMaxMinAvailable,
      accessibilityLabel: "Max/Min",
      panelID: "max/min-panel",
    },
  ];

  if (sellingPlan.billingPolicy?.recurring?.interval !== SellingPlanInterval.Day) {
    tabs.push({
      id: `anchor_${index + 1}`,
      content: "Anchor",
      accessibilityLabel: "Anchor",
      panelID: "anchor-panel",
    });
  }

  const renderSelectedTab = () => {
    const sellingPlanNumber = index + 1;
    switch (selectedTab) {
      case SubscriptionProgramTabs.PREPAID: {
        return (
          <LegacyCard.Section>
            <LegacyStack vertical spacing="loose">
              <BannerContainer>
                <Banner icon={CapturePaymentMinor}>
                  Use prepaid settings when you&apos;d like customers to be able to pay upfront for a set number
                  of subscription deliveries.{" "}
                  <a
                    id={`selling-plan__prepaid__learn-more-about-prepaid_${sellingPlanNumber}`}
                    href="https://help.smartrr.com/docs/support/subscription-setup/how-do-i-offer-prepaid-subscriptions"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Learn more about prepaid.
                  </a>
                </Banner>
              </BannerContainer>
              {isPrepaidAvailable ? (
                <React.Fragment>
                  <LegacyCard.Section>
                    <LegacyStack vertical spacing="baseTight">
                      <Text variant="headingXs" as="h3">
                        Delivery schedule
                      </Text>
                      <EqualWidthItemsContainer>
                        <TextField
                          id={`selling-plan__prepaid__interval-between-deliveries_${sellingPlanNumber}`}
                          autoComplete="off"
                          label="Interval between deliveries"
                          type="number"
                          min="1"
                          max={billingIntervalCount}
                          value={deliveryIntervalCount ? String(deliveryIntervalCount) : ""}
                          error={validationErrors?.sellingPlans?.[
                            index
                          ]?.deliveryPolicy?.recurring?.intervalCount?._errors.join(" ")}
                          onChange={intervalCount => {
                            onUpdateSellingPlan(index, {
                              "deliveryPolicy.recurring.intervalCount": Number(intervalCount),
                            });
                          }}
                        />
                        <Select
                          id={`selling-plan__prepaid__interval-type_${sellingPlanNumber}`}
                          label="Interval type"
                          helpText="Will be the same interval type as billing schedule in basic details"
                          options={intervalOptions}
                          value={sellingPlan.deliveryPolicy?.recurring?.interval}
                          onChange={interval => {
                            onUpdateSellingPlan(index, {
                              "billingPolicy.recurring.interval": interval,
                              "billingPolicy.recurring.anchors": [],
                              "deliveryPolicy.recurring.interval": interval,
                              "deliveryPolicy.recurring.anchors": [],
                              "deliveryPolicy.recurring.cutoff": 0,
                              "deliveryPolicy.recurring.preAnchorBehavior":
                                SellingPlanRecurringDeliveryPolicyPreAnchorBehavior.Asap,
                            });
                          }}
                        />
                      </EqualWidthItemsContainer>
                    </LegacyStack>
                  </LegacyCard.Section>

                  <LegacyCard.Section>
                    <LegacyStack vertical spacing="baseTight">
                      <Text variant="headingXs" as="h3">
                        Swapping from customer account portal
                      </Text>

                      <Text color="subdued" as="p">
                        Swapping is disabled by default for prepaid plans. This prevents swapping in a more
                        expensive product after being billed for a cheaper one.
                      </Text>

                      <Checkbox
                        id={`selling-plan__prepaid__enable-swapping-by-customers_${sellingPlanNumber}`}
                        data-testid={`selling-plan__prepaid__enable-swapping-by-customers_${sellingPlanNumber}`}
                        label="Enable swapping by customers"
                        checked={sellingPlan.enableSwappingByCustomers}
                        onChange={checked => onUpdateSellingPlan(index, { enableSwappingByCustomers: checked })}
                      />
                    </LegacyStack>
                  </LegacyCard.Section>
                </React.Fragment>
              ) : (
                <LegacyStack alignment="center" distribution="equalSpacing">
                  <Text variant="bodyMd" as="span" color="subdued">
                    Upgrade to 📈 Grow to access prepaid settings.{" "}
                  </Text>
                  <Button removeUnderline primary url="/admin/smartrr-account">
                    Upgrade
                  </Button>
                </LegacyStack>
              )}
            </LegacyStack>
          </LegacyCard.Section>
        );
      }
      case SubscriptionProgramTabs.ANCHOR: {
        return (
          <LegacyCard.Section>
            <LegacyStack vertical spacing="loose">
              <BannerContainer>
                <Banner icon={CalendarMajor}>
                  Use anchor settings when you&apos;d like to set a specific date on which the billing interval
                  calculations should be made.{" "}
                  <a
                    id={`selling-plan__anchor__learn-more-about-anchor-dates_${sellingPlanNumber}`}
                    href="https://help.smartrr.com/docs/support/subscription-setup/what-are-subscription-anchor-dates"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Learn more about anchor dates.
                  </a>
                </Banner>
              </BannerContainer>
              <AnchorDatesForm
                sellingPlanNumber={sellingPlanNumber}
                anchors={anchors as SellingPlanAnchorInput[]}
                intervalType={
                  (sellingPlan.billingPolicy?.recurring?.interval ??
                    SellingPlanInterval.Week) as SellingPlanInterval
                }
                onAnchorsUpdate={(anchors: SellingPlanAnchorInput[]) => {
                  if (anchors.length > 0) {
                    onUpdateSellingPlan(index, {
                      "billingPolicy.recurring.anchors": anchors,
                      "deliveryPolicy.recurring.anchors": anchors,
                    });
                  } else {
                    onUpdateSellingPlan(index, {
                      "billingPolicy.recurring.anchors": [],
                      "deliveryPolicy.recurring.anchors": [],
                      "deliveryPolicy.recurring.cutoff": 0,
                      "deliveryPolicy.recurring.preAnchorBehavior":
                        SellingPlanRecurringDeliveryPolicyPreAnchorBehavior.Asap,
                    });
                  }
                }}
              />
              {anchors.length > 0 && (
                <EqualWidthItemsContainer>
                  <TextField
                    id={`selling-plan__anchor__cutoff_${sellingPlanNumber}`}
                    autoComplete="off"
                    label="Cutoff (days)"
                    helpText="How many days in advance of anchor an order would need to be placed to qualify for the upcoming delivery cycle"
                    type="number"
                    value={`${sellingPlan.deliveryPolicy?.recurring?.cutoff ?? 0}`}
                    error={validationErrors?.sellingPlans?.[index]?.deliveryPolicy?.recurring?._errors.join(" ")}
                    min={0}
                    max={90}
                    onChange={cutoff => {
                      const cutoffNum = Number.parseInt(cutoff, 10);
                      onUpdateSellingPlan(index, {
                        "deliveryPolicy.recurring.cutoff": cutoffNum >= 0 ? cutoffNum : null,
                      });
                    }}
                  />
                  <Select
                    id={`selling-plan__anchor__pre-anchor-behavior_${sellingPlanNumber}`}
                    label="Pre-Anchor Behavior"
                    helpText={
                      <LegacyStack vertical spacing="loose">
                        <p>
                          Delivery behavior of the first fulfillment when the order is placed within the cutoff
                          period
                        </p>
                        <p>
                          ASAP means orders placed inside a cutoff can be fulfilled or delivered by the next
                          anchor. NEXT means orders placed inside a cutoff can be fulfilled or delivered at the
                          following anchor.
                        </p>
                      </LegacyStack>
                    }
                    options={preAnchorBehaviorOptions}
                    value={sellingPlan.deliveryPolicy?.recurring?.preAnchorBehavior ?? "ASAP"}
                    onChange={preAnchorBehavior => {
                      onUpdateSellingPlan(index, {
                        "deliveryPolicy.recurring.preAnchorBehavior": preAnchorBehavior,
                      });
                    }}
                  />
                </EqualWidthItemsContainer>
              )}
            </LegacyStack>
          </LegacyCard.Section>
        );
      }
      case SubscriptionProgramTabs.MAXMIN: {
        return (
          <MaxMinCardContainer>
            <LegacyCard.Section>
              <LegacyStack vertical spacing="loose">
                <BannerContainer>
                  <Banner icon={ClockMajor}>
                    Use max/min settings when you&apos;d like a subscription to end after a certain number of
                    billing cycles.{" "}
                    <a
                      id={`selling-plan__min_max__learn-max-min_${sellingPlanNumber}`}
                      href="https://help.smartrr.com/docs/support/subscription-setup/what-are-maximum-minimum-finite-subscription-plans"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Learn max/min (finite) subscriptions.
                    </a>
                  </Banner>
                </BannerContainer>
                {isMaxMinAvailable ? (
                  <React.Fragment>
                    <LegacyStack vertical spacing="baseTight">
                      <Text variant="headingXs" as="h3">
                        Finite Billing Cycles
                      </Text>
                      <FormLayout>
                        <FormLayout.Group condensed>
                          <TextField
                            id={`selling-plan__min_max__minimum_billing_cycles_${sellingPlanNumber}`}
                            autoComplete="off"
                            label="Minimum billing cycles"
                            type="number"
                            min={0}
                            value={minimumBillingCycles ? String(minimumBillingCycles) : ""}
                            onChange={minCycles => {
                              const numMinCycles = Number(minCycles);

                              if (
                                !maximumBillingCycles ||
                                !numMinCycles ||
                                numMinCycles <= maximumBillingCycles
                              ) {
                                onUpdateSellingPlan(index, {
                                  "billingPolicy.recurring.minCycles": numMinCycles === 0 ? null : numMinCycles,
                                });
                              }
                            }}
                          />
                          <TextField
                            id={`selling-plan__min_max__maximum_billing_cycles_${sellingPlanNumber}`}
                            autoComplete="off"
                            label="Maximum billing cycles"
                            type="number"
                            min={minimumBillingCycles ?? 0}
                            value={maximumBillingCycles ? String(maximumBillingCycles) : ""}
                            onChange={maxCycles => {
                              const numMaxCycles = Number(maxCycles);

                              if (
                                !minimumBillingCycles ||
                                !numMaxCycles ||
                                numMaxCycles >= minimumBillingCycles
                              ) {
                                onUpdateSellingPlan(index, {
                                  "billingPolicy.recurring.maxCycles": numMaxCycles === 0 ? null : numMaxCycles,
                                });
                              }
                            }}
                          />
                        </FormLayout.Group>
                      </FormLayout>
                    </LegacyStack>
                  </React.Fragment>
                ) : (
                  <LegacyStack alignment="center" distribution="equalSpacing">
                    <Text variant="bodyMd" as="span" color="subdued">
                      Upgrade to 📈 Grow to access max/min settings.{" "}
                    </Text>
                    <Button removeUnderline primary url="/admin/smartrr-account">
                      Upgrade
                    </Button>
                  </LegacyStack>
                )}
              </LegacyStack>
              <BannerContainer>
                <Banner status="info">
                  <p>
                    As of July 1, 2022, merchants selling in{" "}
                    <SemiBoldText>California, United States</SemiBoldText> may not be able to offer minimum
                    required billing cycles. Please{" "}
                    <a
                      id={`selling-plan__min_max__read-here_${sellingPlanNumber}`}
                      href="https://www.jdsupra.com/legalnews/amendments-to-california-s-automatic-4857722/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      read here
                    </a>{" "}
                    for more information.
                  </p>
                </Banner>
              </BannerContainer>
            </LegacyCard.Section>
          </MaxMinCardContainer>
        );
      }
      default: {
        return (
          <React.Fragment>
            <LegacyCard.Section>
              <LegacyStack vertical spacing="loose">
                <TextField
                  id={`selling-plan__basic-details__display_${sellingPlanNumber}`}
                  autoComplete="off"
                  requiredIndicator={true}
                  label={"Display Label"}
                  helpText="Label of the plan option selector"
                  placeholder="Example: Monthly"
                  value={sellingPlan.displayLabel || ""}
                  error={validationErrors?.sellingPlans?.[index]?.displayLabel?._errors.join(" ")}
                  onChange={displayLabel => onUpdateSellingPlan(index, { displayLabel })}
                />
                <TextField
                  id={`selling-plan__basic-details__cart-checkout-label_${sellingPlanNumber}`}
                  autoComplete="off"
                  requiredIndicator={true}
                  label="Cart & Checkout Label"
                  helpText='Enter "{{H}}" to hide this label from being displayed'
                  placeholder="Example: Delivered monthly"
                  value={sellingPlan.cartCheckoutLabel || ""}
                  error={validationErrors?.sellingPlans?.[index]?.cartCheckoutLabel?._errors.join(" ")}
                  onChange={cartCheckoutLabel => onUpdateSellingPlan(index, { cartCheckoutLabel })}
                />
              </LegacyStack>
            </LegacyCard.Section>
            <LegacyCard.Section>
              <LegacyStack vertical spacing="baseTight">
                <Text variant="headingXs" as="h3">
                  Billing Schedule
                </Text>
                <EqualWidthItemsContainer>
                  <TextField
                    id={`selling-plan__basic-details__billing-schedule__interval-between-billings_${sellingPlanNumber}`}
                    autoComplete="off"
                    label="Interval between billings"
                    type="number"
                    min="1"
                    helpText={
                      isPrepaidAvailable ? "Must be a multiple of the delivery interval in prepaid details" : ""
                    }
                    value={billingIntervalCount ? String(billingIntervalCount) : ""}
                    error={validationErrors?.sellingPlans?.[
                      index
                    ]?.billingPolicy?.recurring?.intervalCount?._errors.join(" ")}
                    onChange={intervalCount => {
                      const newBillingIntervalCount = Number(intervalCount) || 1;
                      onUpdateSellingPlan(index, {
                        "billingPolicy.recurring.intervalCount": newBillingIntervalCount,
                        "deliveryPolicy.recurring.intervalCount": newBillingIntervalCount,
                      });
                    }}
                  />
                  <Select
                    id={`selling-plan__basic-details__billing-schedule__interval-type_${sellingPlanNumber}`}
                    label="Interval type"
                    options={intervalOptions}
                    helpText={
                      isPrepaidAvailable
                        ? "Will be the same interval type as delivery schedule in prepaid details"
                        : ""
                    }
                    value={sellingPlan.billingPolicy?.recurring?.interval}
                    onChange={interval => {
                      onUpdateSellingPlan(index, {
                        "billingPolicy.recurring.interval": interval,
                        "billingPolicy.recurring.anchors": [],
                        "deliveryPolicy.recurring.interval": interval,
                        "deliveryPolicy.recurring.anchors": [],
                        "deliveryPolicy.recurring.cutoff": 0,
                        "deliveryPolicy.recurring.preAnchorBehavior":
                          SellingPlanRecurringDeliveryPolicyPreAnchorBehavior.Asap,
                      });
                    }}
                  />
                </EqualWidthItemsContainer>
              </LegacyStack>
            </LegacyCard.Section>
            <LegacyCard.Section>
              <LegacyStack vertical spacing="baseTight">
                <Text variant="headingXs" as="h3">
                  Discount Incentive
                </Text>
                <PricingPolicies
                  sellingPlanNumber={sellingPlanNumber}
                  sellingPlan={sellingPlan}
                  onPoliciesUpdate={pricingPolicies => onUpdateSellingPlan(index, { pricingPolicies })}
                />
              </LegacyStack>
            </LegacyCard.Section>
          </React.Fragment>
        );
      }
    }
  };

  return (
    <React.Fragment>
      <LegacyCard.Section>
        <LegacyStack distribution="equalSpacing">
          <Text variant="headingMd" as="h2">
            {sellingPlan.displayLabel || `Plan #${index + 1}`}
          </Text>
          {Boolean(sellingPlan.id) && (
            <div data-testid={`smartrr-edit-plan-duplicate-${index}`}>
              <Button
                plain
                disabled={hideAddAnotherPlan}
                icon={DuplicateMinor}
                onClick={() =>
                  setSellingPlanInputs(plans => plans.concat(createSmartrrSellingPlanCopy(sellingPlan)))
                }
              />
            </div>
          )}
        </LegacyStack>
      </LegacyCard.Section>
      {sellingPlan.shopifyId ? (
        <SellingPlanID>
          <CopyClipboard>
            <span className="copy-btn">
              <Button
                plain
                icon={ClipboardMinor}
                onClick={() => copyToClipboard(sellingPlan.shopifyId, "Copied to clipboard", addToast)}
              >
                Copy
              </Button>
            </span>
          </CopyClipboard>
          <TextField
            id={`smartrr-selling-plan-group__plan-id-${index + 1}`}
            label="Your plan ID"
            autoComplete="off"
            value={sellingPlan.shopifyId || sellingPlan.id || ""}
            onChange={() => null}
            disabled
          />
        </SellingPlanID>
      ) : null}
      <Tabs tabs={tabs} selected={selectedTab} onSelect={setSelectedTab} fitted>
        <TabContainer>{renderSelectedTab()}</TabContainer>
      </Tabs>
    </React.Fragment>
  );
}
